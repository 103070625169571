import React from 'react'

export default function Media() {
  return (
   <div className="media">
   <ul className="media__list">
      <li className='media__item' style={{scale: '0.8'}}>
         <a href="https://dribbble.com/Proyect_io" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
               <path d="M17.5 0C7.84 0 0 7.84 0 17.5C0 27.16 7.84 35 17.5 35C27.1425 35 35 27.16 35 17.5C35 7.84 27.1425 0 17.5 0ZM29.0588 8.0675C31.2127 10.6911 32.4045 13.9726 32.4362 17.367C31.9445 17.2725 27.0095 16.2663 22.036 16.8927C21.9223 16.646 21.826 16.38 21.714 16.114C21.4047 15.3842 21.0755 14.6629 20.727 13.951C26.2307 11.711 28.7367 8.484 29.0588 8.0675ZM17.5 2.58125C21.2975 2.58125 24.7695 4.00575 27.4085 6.34025C27.1425 6.71825 24.8832 9.737 19.5685 11.7303C17.1203 7.23275 14.406 3.549 13.9878 2.98025C15.139 2.71133 16.3177 2.57743 17.5 2.58125ZM11.1422 3.9865C13.1447 6.76275 14.9949 9.64575 16.6845 12.6228C9.6985 14.483 3.52975 14.4428 2.8665 14.4428C3.34732 12.1802 4.34439 10.0593 5.77992 8.24551C7.21544 6.43175 9.05061 4.97418 11.1422 3.9865ZM2.54275 17.5175V17.0625C3.19025 17.08 10.4387 17.1763 17.899 14.9362C18.3365 15.771 18.7337 16.625 19.1135 17.479C18.9228 17.5368 18.7145 17.5928 18.5255 17.6505C10.8185 20.1355 6.71825 26.9307 6.377 27.5013C3.90893 24.7617 2.54178 21.2048 2.54275 17.5175ZM17.5 32.4573C14.1775 32.4632 10.949 31.354 8.33175 29.3072C8.59775 28.756 11.6357 22.9093 20.062 19.9675C20.1005 19.95 20.1197 19.95 20.1565 19.929C21.5782 23.5959 22.6465 27.3902 23.3467 31.2603C21.5002 32.0548 19.5103 32.4622 17.5 32.4573ZM25.8317 29.8935C25.6812 28.9835 24.8832 24.6173 22.9268 19.2465C27.6168 18.5063 31.717 19.7207 32.228 19.8923C31.9086 21.9101 31.1756 23.8401 30.0749 25.5612C28.9742 27.2823 27.5296 28.7572 25.8317 29.8935Z" fill="black"/>
            </svg>
         </a>
      </li>
      <li className='media__item'>
         <a href="https://www.behance.net/Proyect_io" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 46 46" fill="none">
               <path d="M17.9933 22.0983C19.7915 20.6949 20.9507 18.5096 20.9507 16.0574C20.9507 11.8325 17.5136 8.39539 13.2889 8.39539H1.52589C1.1212 8.39539 0.733084 8.55615 0.446924 8.84231C0.160763 9.12847 0 9.51659 0 9.92128L0 36.0798C0 36.4845 0.160763 36.8726 0.446924 37.1587C0.733084 37.4449 1.1212 37.6057 1.52589 37.6057H13.2889C17.959 37.6057 21.7582 33.8066 21.7582 29.1372C21.7582 26.2064 20.2618 23.6193 17.9933 22.0983ZM13.2889 11.4472C15.8308 11.4472 17.8989 13.5155 17.8989 16.0574C17.8989 18.5993 15.8308 20.6674 13.2889 20.6674H3.05179V11.4472H13.2889ZM13.2889 34.5539H3.05179V23.7192H13.2889C16.2762 23.7192 18.7064 26.1497 18.7064 29.137C18.7064 32.1238 16.2762 34.5539 13.2889 34.5539ZM34.826 15.258C28.6648 15.258 23.6522 20.2705 23.6522 26.4319C23.6522 32.5932 28.6648 37.6057 34.826 37.6057C37.8106 37.6057 40.6166 36.4433 42.7273 34.3327C43.0084 34.0455 43.1649 33.6591 43.1627 33.2572C43.1606 32.8553 43 32.4705 42.7158 32.1863C42.4317 31.9022 42.0469 31.7416 41.645 31.7394C41.2431 31.7373 40.8566 31.8938 40.5694 32.1749C39.035 33.7089 36.9954 34.5539 34.826 34.5539C30.8692 34.5539 27.566 31.709 26.8498 27.9577H44.4741C44.8788 27.9577 45.2669 27.797 45.5531 27.5108C45.8392 27.2247 46 26.8365 46 26.4319C46 20.2705 40.9873 15.258 34.826 15.258ZM34.826 18.3098C38.7827 18.3098 42.086 21.1545 42.8023 24.906H26.8498C27.566 21.1545 30.8692 18.3098 34.826 18.3098ZM27.9603 12.2545H40.8781C41.0785 12.2545 41.2769 12.215 41.462 12.1383C41.6471 12.0616 41.8154 11.9492 41.9571 11.8075C42.0987 11.6659 42.2111 11.4976 42.2878 11.3125C42.3645 11.1274 42.404 10.929 42.404 10.7286C42.404 10.3239 42.2432 9.93577 41.9571 9.64961C41.6709 9.36345 41.2828 9.20268 40.8781 9.20268H27.9603C27.5556 9.20268 27.1675 9.36345 26.8813 9.64961C26.5951 9.93577 26.4344 10.3239 26.4344 10.7286C26.4344 10.929 26.4738 11.1274 26.5505 11.3125C26.6272 11.4976 26.7396 11.6659 26.8813 11.8075C27.023 11.9492 27.1912 12.0616 27.3763 12.1383C27.5615 12.215 27.7599 12.2545 27.9603 12.2545Z"/>
            </svg>
         </a>
      </li>
      <li className='media__item' style={{scale: '0.7'}}>
         <a href="https://twitter.com/proyect_io" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 37 38" fill="none">
               <g clipPath="url(#clip0_22_2310)">
                 <path d="M22.0236 16.1688L35.8 0.5H32.5366L20.5695 14.1022L11.0186 0.5H0L14.446 21.0709L0 37.5H3.2634L15.8927 23.1325L25.9814 37.5H37M4.44123 2.90793H9.45473L32.5341 35.2104H27.5194"/>
               </g>
               <defs>
                 <clipPath id="clip0_22_2310">
                   <rect width="37" height="37" fill="white" transform="translate(0 0.5)"/>
                 </clipPath>
               </defs>
            </svg>
         </a>
      </li>
      
   </ul>
</div>
  )
}
